var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "work-order-service-design-component table-component" },
    [
      _c("div", { staticClass: "page-header" }, [
        _c(
          "div",
          { staticClass: "page-breadcrumb" },
          [
            _c(
              "a-breadcrumb",
              [
                _c(
                  "a-breadcrumb-item",
                  [_c("router-link", { attrs: { to: "/" } }, [_vm._v("首页")])],
                  1
                ),
                _c(
                  "a-breadcrumb-item",
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/dashboard/professional-relation" } },
                      [_vm._v("工单设置")]
                    )
                  ],
                  1
                ),
                _c(
                  "a-breadcrumb-item",
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/dashboard/work-order-form" } },
                      [_vm._v("服务设计")]
                    )
                  ],
                  1
                ),
                _c("a-breadcrumb-item", [_vm._v(_vm._s(_vm.title))])
              ],
              1
            ),
            _c(
              "jtl-button",
              { staticClass: "save-btn", attrs: { type: "primary" } },
              [_vm._v("保存")]
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "tag-wrapper" },
        [
          _c(
            "a-radio-group",
            {
              staticClass: "tags",
              model: {
                value: _vm.showTag,
                callback: function($$v) {
                  _vm.showTag = $$v
                },
                expression: "showTag"
              }
            },
            [
              _c("a-radio-button", { attrs: { value: "fields" } }, [
                _vm._v(" 数据库设计 ")
              ]),
              _c("a-icon", {
                staticClass: "left-dots",
                attrs: { type: "small-dash" }
              }),
              _c("a-icon", {
                staticClass: "arrow-right-icon",
                attrs: { type: "arrow-right" }
              }),
              _c("a-icon", {
                staticClass: "right-dots",
                attrs: { type: "small-dash" }
              }),
              _c("a-radio-button", { attrs: { value: "ui" } }, [
                _vm._v(" 表单设计器 ")
              ]),
              _c("a-icon", {
                staticClass: "left-dots",
                attrs: { type: "small-dash" }
              }),
              _c("a-icon", {
                staticClass: "arrow-right-icon",
                attrs: { type: "arrow-right" }
              }),
              _c("a-icon", {
                staticClass: "right-dots",
                attrs: { type: "small-dash" }
              }),
              _c("a-radio-button", { attrs: { value: "flow" } }, [
                _vm._v(" 流程设计器 ")
              ]),
              _c("a-icon", {
                staticClass: "left-dots",
                attrs: { type: "small-dash" }
              }),
              _c("a-icon", {
                staticClass: "arrow-right-icon",
                attrs: { type: "arrow-right" }
              }),
              _c("a-icon", {
                staticClass: "right-dots",
                attrs: { type: "small-dash" }
              }),
              _c("a-radio-button", { attrs: { value: "config" } }, [
                _vm._v(" 服务配置 ")
              ]),
              _c("a-icon", {
                staticClass: "left-dots",
                attrs: { type: "small-dash" }
              }),
              _c("a-icon", {
                staticClass: "arrow-right-icon",
                attrs: { type: "arrow-right" }
              }),
              _c("a-icon", {
                staticClass: "right-dots",
                attrs: { type: "small-dash" }
              }),
              _c(
                "a-radio-button",
                {
                  attrs: { value: _vm.showTag },
                  on: { click: _vm.showDeploy }
                },
                [_vm._v(" 部署服务 ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.showTag === "fields"
        ? _c("field-component", { attrs: { "form-id": _vm.formId } })
        : _vm.showTag === "ui"
        ? _c("ui-component", { attrs: { "form-id": _vm.formId } })
        : _vm.showTag === "flow"
        ? _c("flow-component", { attrs: { "flow-id": _vm.processId } })
        : _vm.showTag === "config"
        ? _c("config-component", { attrs: { "config-id": _vm.serviceId } })
        : _vm._e(),
      _c(
        "a-modal",
        {
          attrs: { visible: _vm.deployVisible },
          on: { ok: _vm.deploy, cancel: _vm.handleCancel }
        },
        [_c("div", [_vm._v("确认部署？")])]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }